import React from "react";
import Subred from "../assets/Subtract-red.png";
import Eclipsered from "../assets/Ellipse-red-1.png";
import EclipseRed from "../assets/Ellipse-red-2.png";
import Teachers from "../assets/Teachers.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./Educator-video.css";
function EducatorVideo() {
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 2,
      },
      1000: {
        items: 1,
      },
    },
    nav: false,
    dots: true,
  };

  return (
    <>
      <div className="bodyFont edct-vd container-fluid text-center pb-3">
        <img className="teachers" src={Teachers} />
        <div class="tch-crd container  card text-center mb-3">
          <img class="Eclipsered" src={Eclipsered} />
          <img class="EclipseRed" src={EclipseRed} />
          <div class="crd-cntnr">
            <div class="card-body">
              <h5 class="card-title mt-5">
                Our content and pedagogy is backed by evidence and revised by
                ICT Educators from Leading Schools
              </h5>
              <p class="card-text mt-3">
                Andrew Teaches Coding is revised by teachers for the use of
                teachers. We maintain a deep collaboartive spirit with our
                teacher community, working closely on improving both the content
                and virtual learning enviroment. Making sure our tools represent
                the actual needs of educators in classrooms.
              </p>
              <h5 class="mt-5"><b> Hear what the Educators have to say</b></h5>
            </div>
          </div>

          <OwlCarousel
            className="carousel"
            loop
            margin={4}
            items={1}
            nav
            autoplay={false}
            {...options}
          >
            <div class="item">
            <iframe width="60%" height="400" src="https://www.youtube.com/embed/sgcc71np0Bw?si=985tviLW3AYani0T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
           
          </OwlCarousel>
        </div>
      </div>
    </>
  );
}
export default EducatorVideo;
