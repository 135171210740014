import React from "react";
import Home from "./components/Home.js";
import EducatorVideo from "./components/Educator-video.js";
import Footer from "./components/Footer.js";
import Form from "./components/Form.js";
import KeyFeatures from "./components/Key-features.js";
import Price from "./components/Price.js";
import Schools from "./components/Schools.js";
import TeacherTraining from "./components/Teacher-training.js";
import Textbook from "./components/Textbook.js";

function App() {
  return (
    <div>
      <div>
        <Home />
        <Schools />
        <Textbook />
        <EducatorVideo />
        <KeyFeatures />
        <TeacherTraining />
        <Price />
        <Form />
        <Footer />
      </div>
    </div>
  );
}

export default App;
