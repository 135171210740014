import React from "react";
import "./Price.css";
import "bootstrap/dist/css/bootstrap.css";
import Eclipse1 from "../assets/Eclipse1.png";
import Eclipse2 from "../assets/Eclipse2.png";
import Teacher from "../assets/Teacher.png";
import Subtract from "../assets/Subtract.png";
import ShortTeach from "../assets/short-teacher.png";
import Round from "../assets/round-mb.png";
import Pricing from "../assets/Pricing-img.png";
import Tick from "../assets/Tick Square.png";
import Closesquare from "../assets/Close Square.png";
import Whiteboy from "../assets/Free.png";
import Foreigngirl from "../assets/Foreign Girl.png";
import Nigerianboy from "../assets/naij.png";
import Greenstar from "../assets/Green-star.png";
import $ from 'jquery'

function Price() {


  function selectPlan(e){ 
     let id = e.target.id
     let formField = document.getElementById('plan')

     if(e.target.id == "starter"){ 
        $(formField).val('1')
        $('html,body').animate({
          scrollTop: $("#payments").offset().top},
          'fast');
   
     }

     if(e.target.id == "basic"){ 
      $(formField).val('2')
      $('html,body').animate({
        scrollTop: $("#payments").offset().top},
        'fast');
     }

     if(e.target.id == "pro"){ 
      $(formField).val('3')
      $('html,body').animate({
        scrollTop: $("#payments").offset().top},
        'fast');
     }
  }
  return (
    <div class="main bodyFont" id="price">
      <div class="test">
        <div className="pricing-section">
          <div class=" text-center prc-sct-1 ">
            <img class="prc-sct" src={Pricing} />

            <p className="prc-p">
              Select from one of our plans. Try out our free plan and feel free
              to switch to get premium access to our products.
            </p>
          </div>
          <div class="  cont">
            <div class=" prnt displayCont justify-content-around">
              <div class="mx-auto crd-1 card  prc-crd">
                <div class="card-body">
                  <h6 class="pt-4 card-title">Starter</h6>
                  <h6 class="prc-h6 card-subtitle mb-2 ">&#8358;5000 <span className="spn">Annually</span>
                  </h6>
                  <hr></hr>
                  <p class="card-text">Each book come with </p>
                  <div class=" displayCont justify-content-around">
                    <ul className="prc-list">
                      <li>
                        <img className="prc-img" src={Tick} />
                         4 chapters
                      </li>
                      <li>
                        <img src={Tick} />
                        100 lesson evaluations
                      </li>
                      <li>
                        <img src={Tick} />
                        Portfolio storage (4)
                      </li>
                      <li>
                        <img src={Tick} />
                        Teacher support
                      </li>
                      <li>
                        <img src={Tick} />
                        Teacher's copy
                      </li>
                      <li>
                        <img src={Tick} />
                        Reporting
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Assessment Builder
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Graded assessments
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Real-time Projects
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Live Editor Streaming 
                      </li>
                      
                      
                    </ul>

                    <div class="d-block d-sm-none" style={{width:'25%', margin:'auto'}}> 
                    <img class="boy align-self-end" src={Whiteboy} />
                    </div>
                    <img class="boy me-3 align-self-end d-none d-sm-block" src={Whiteboy} />
                  </div>
                  <div class="mt-4 text-center">
                    <button className="prc-btn" id="starter" onClick={selectPlan} > Start now</button>
                  </div>
                </div>
              </div>
              <div class="mx-auto crd-2 card prc-crd">
                <div class="card-body">
                  <h6 class="pt-4 card-title">Basic</h6>
                  <h6 class=" pt-4 prc-h6 card-subtitle mb-2 ">
                    &#8358;10,000 <span className="spn">Annually</span>
                  </h6>
                  <hr></hr>
                  <p class="card-text">Each book come with</p>
                  <div class="displayCont justify-content-around">
                    <ul className="prc-list">
                      <li>
                        <img className="prc-img" src={Tick} /> 
                         4 chapters
                      </li>
                      <li>
                        <img src={Tick} />
                        100 lesson evaluations
                      </li>
                      <li>
                        <img className="prc-img" src={Tick} />
                        Graded assessments
                      </li>
                      <li>
                        <img className="prc-img" src={Tick} />
                        Portfolio storage (10)
                      </li>
                      <li>
                        <img src={Tick} />
                        Teacher support
                      </li>
                      <li>
                        <img src={Tick} />
                        Teacher's copy
                      </li>
                      <li>
                        <img src={Tick} />
                        Reporting
                      </li>
                      <li>
                        <img src={Tick} />
                        Graded assessments
                      </li>
                      <li>
                        <img src={Tick} />
                        Assessment Builder
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Real-time Projects
                      </li>
                      <li>
                        <img src={Closesquare} />
                        Live Editor Streaming 
                      </li>
                    </ul>
                    <div class="d-block d-sm-none" style={{width:'35%', margin:'auto'}}> 
                    <img class="frgn align-self-end" src={Foreigngirl} />
                    </div>
                    <img class="frgn align-self-end d-none d-sm-block" src={Foreigngirl} />
                  </div>
                  <div class="mt-4 text-center">
                    <button className="basic-btn prc-btn" id="basic" onClick={selectPlan}>Start now</button>
                  </div>
                </div>
              </div>
              <div class="mx-auto crd-3 card prc-crd">
                <div class="card-body">
                  <h6 class="pt-4 card-title">Pro</h6>
                  <h6 class="prc-h6 card-subtitle mb-2 ">
                    &#8358;10,000 <span className="spn">Annually</span>
                  </h6>
                  <hr></hr>
                  <p class="card-text">Each book come with</p>
                  <div class="mb-3 displayCont">
                    <ul className="prc-list ">
                      <li>
                        <img className="prc-img" src={Tick} />
                         4 chapters
                      </li>
                      <li>
                        <img src={Tick} />
                        100 lesson evaluations
                      </li>
                      <li>
                        <img src={Tick} />
                        Graded assessments
                      </li>
                      <li>
                        <img src={Tick} />
                        Portfolio storage (Infinite)
                      </li>
                    
                      <li>
                        <img src={Tick} />
                        Teacher support
                      </li>
                      <li>
                        <img src={Tick} />
                        Teacher's copy
                      </li>
                      <li>
                        <img src={Tick} />
                        Reporting
                      </li>
                      <li>
                        <img src={Tick} />
                        Graded assessments
                      </li>
                      <li>
                        <img src={Tick} />
                        Assessment Builder
                      </li>
                      <li>
                        <img src={Tick} />
                        Real-time Projects
                      </li>
                      <li>
                        <img src={Tick} />
                        Live Editor Streaming 
                      </li>
                    </ul>
                    <div class="d-block d-sm-none" style={{width:'50%', margin:'auto'}}> 
                    <img class="naij align-self-end" src={Nigerianboy} />
                    </div>
                    <img class=" naij align-self-end d-none d-sm-block" src={Nigerianboy} />
                  </div>
                  <div class="alrt d-flex align-items-center ">
                    <img class="ms-2" src={Greenstar} />
                    <span class="ms-2 p-2">
                      The PRO plan comes with an additional investment of N70,000 for real-time features activation
                      in your teacher's copy and students workbooks. 
                    </span>
                  </div>

                  <div class="mt-4 text-center">
                    <button className="prc-btn-2 prc-btn" id="pro" onClick={selectPlan}>Start now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Price;
