import { useState, useEffect, Component } from "react";
import Big from "../assets/Mock Ups.jpg";
import Small from "../assets/Frame 3960.jpg";
import Modal from 'react-bootstrap/Modal';
import payStackPop from '@paystack/inline-js';
import andrew from "../assets/Speech Box.png";
import $ from 'jquery';
import "./Form.css";
import { Button, FormGroup } from "react-bootstrap";


function Form() {


    const [amount, setAmount] = useState("")
    const [amountB, setAmountB] = useState("")
    const [school, setSchool] = useState("")
    const [email, setEmail] = useState("")
    const [representative, setRepresentative] = useState("")
    const [plan, setPlan] = useState("")
    const [licences, setLicences] = useState("")
    const [transStatus, setTransStatus] = useState("")
    const [loader, setLoader] = useState(false)

  async function pay(e) {
        e.preventDefault()
          const data = { 
            amount,
            email
          }

          alert(data)
        
          const submitPaymentInfo = await fetch('https://atcbrain-7de683f08325.herokuapp.com/payments',{ 
            method:"POST",
            headers:{ 
              "content-type" : "application/json"
             },
             body:JSON.stringify(data),
           mode:"cors",
           credentials:"include"
          })
          .then(response => response.json())
          .then(response => {
              if(!response){
                return false
              }
        
           if(response){
               const popup = new payStackPop()
              popup.resumeTransaction(response.data.access_code)
            }

                //VERIFY PAYMENT
                async function verifyPayment(){
                  let transRef = response.data.reference
                 const submitPaymentInfo = await fetch(`https://api.paystack.co/transaction/verify/${transRef}`,{ 
                  method:"GET",
                  headers:{ 
                    'Authorization': 'Bearer sk_test_5c293a2e9e590357a6ba810f64ce8180706e6dde'
                   },
                 mode:"cors"
                })
                .then(response => response.json())
                .then(response => {
                    if(response.data.amount == amount){
                      setTransStatus(response.data.status)
                      setLoader(true)
                    }
                
  
                })
                }
        })
        
    
        
    }

   const paystack = new payStackPop()

   function calculatePrice(e){ 
     if(plan == "" || plan == "0"){
      e.target.value = ""
         return alert("Please select a book plan first")
     }else{
      setLicences(e.target.value )
       let unitCost = parseInt(plan)
        
       let purchasingAmount = unitCost * e.target.value * 100
       let purchasingAmountB = unitCost * e.target.value 

       setAmount(purchasingAmount)
       setAmountB(purchasingAmountB)
     }


   }

  

  return (
    <div class=" bodyFont d-flex pad blue" id="payments">
      <section class="p-2 d-flex flex-row half-screen" id="sec">
        <div>
          <img src={Big} className="mt-4 mb-5 img1" width="85%" />
          <div class="border-start border-2 secs ">
            <h4 class="text-white mb-4 fw-bold">
             Thank you for choosing Andrew Teaches Coding Interactive textbooks as your preferred
             resource for teaching computer languages at your school.
            </h4>
            <h6 class="text-white fw-light">
             Your purchase comes with free teacher training and support. Your teacher is also automatically
             eligible for our teacher to the year award where they can win prize money of over N300,000. Contact our
             education team for further details on how you can claim these benefits for your school!
            </h6>
          </div>
          <div class=" bg-white rounded-3 d-flex flex-col mak">
            <img src={Small} id="star" class="p-1" />
            <p class=" text-dark text-center margin-off p-1 size-smal center-self">
              Only available on Desktop and Tablet
            </p>
          </div>
        </div>
      </section>
      <section class="  half-screen text-center">
        <form class="bg-white rounded-5 mx-auto" id="form">
          <h4 class=" pt-4 text-center">Supplies Payment Section </h4>
          <h6 class="pb-4 text-center"> Please fill out this form to make yor payments </h6>
          <div class="mb-2 ">
            <input
              class="rounded-5 p-3 form-control"
              type="text"
              id="exampleFormControlInput1"
              placeholder="School Name"
              onChange={(e)=>setSchool(e.target.value)}
            />
          </div>
          <div class="mb-2">
            <input
              class="rounded-5 p-3 form-control"
              type="text"
              id="exampleFormControlTextarea1"
              placeholder="School Representative"
              onChange={(e)=>setRepresentative(e.target.value)}
            />
          </div>
          <div class="mb-2">
            <input
              class="rounded-5 p-3 form-control"
              type="email"
              id="exampleFormControlTextarea1"
              placeholder="Email"
              onChange={(e)=>setEmail(e.target.value)}
            />
          </div>
          <div class="mb-2">
            <select
              class="rounded-5 p-3 form-control"
              type="text"
              id="plan"
              placeholder="Book Plan"
              onChange={(e)=>setPlan(e.target.value)}
            >
               <option value="0">  Select Book Plan   </option>
               <option value="1">  Starter   </option>
               <option value="2"> Basic  </option>
               <option value="3"> PRO </option>

              </select> 
              <select
              class="rounded-5 p-3 form-control"
              type="text"
              id="plan"
              placeholder="Book Plan"
              onChange={(e)=>setPlan(e.target.value)}
            >
               <option value="0">  Do you need a Device & Internet Plan   </option>
               <option value="1">  yes   </option>
               <option value="2"> No  </option>
              </select>
              
          </div>
          <div class="mb-4 ">
            <input
              class="rounded-5 p-3 form-control"
              type="Number"
              id="exampleFormControlTextarea1"
              placeholder="Number of Licences"
              onChange={calculatePrice}
            />
          </div>
          <h4 class=" p-4 text-center"> Your textbooks will cost &#8358;{amountB} </h4>
     
          <h6> <b> Please note device and internet attracts a N5000 service charge per licence which will be added to your total </b> </h6>
     
          <button
            class="btn btn-primary blue text-white rounded-5 p-3 form-control mb-4 pay"
            type="submit" onClick={pay}
          >
            {" "}
            Pay Now{" "}
          </button>
        </form>
         </section>

      <Modal className="padiLoader text-center"
        show={loader}
      >
        <Modal.Header className="padiLoaderHeader">
          <Modal.Title> <img src={andrew} width="100%" /> </Modal.Title>
        </Modal.Header>
        <Modal.Body className="padiLoaderHeader">
        <h5 className="">    Dear {school}  </h5>
          <h6 className="">    Your transaction was {transStatus} . Please, check your email for further details </h6>

          <button
            class="btn btn-primary blue text-white rounded-5 p-3 form-control mb-4 pay"
            type="submit" onClick={()=> setLoader(false) }
          >
            {" "}
            Continue{" "}
          </button>
        </Modal.Body>
      </Modal>
    </div>

    
  );
}
export default Form;
