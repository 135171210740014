import React, { useState, useEffect, Component } from 'react';
import './Home.css'
import logo from '../assets/andrew.svg'
import { Button, FormGroup } from 'react-bootstrap';
import atcLogo from '../assets/andrew.svg';
import {Modal} from 'react-bootstrap';
import banner from '../assets/doddles.png';
import andrew from '../assets/andrew-1.svg';
import $ from 'jquery';
import rectangle from '../assets/rectangle.png';
import union from '../assets/union.png'
import speechBox from '../assets/speechBubble.png'
import greenBox from '../assets/green.png';
import blueBox from '../assets/blue.png';
import amberBox from '../assets/yellow.png';
import purpleBox from '../assets/purple.png';
import redBox from '../assets/red.png';
import Play from '../assets/Polygon.png';
import close from '../assets/close.png';
import lines from '../assets/menu.png';
//import Nav from './nav'

const Home = () => {

   const[firstname, setFirstname] = useState("")
   const [email, setEmail] = useState("")
   const [addModalShow, setAddModalShow] = useState('')
   const [addModalShowLoading, setAddModalShowLoading] = useState('')
   const [voiceMessage, setVoiceMessage] = useState('one')
   const [speechBox, setSpeechBox] = useState("")

  function updateFirstnameField(event) { 
        setFirstname(event.target.value )
    }
  
function updateEmailField (event){ 
    setEmail(event.target.value)
    }
  
function closeNav(){ 
     $('.mobileNav').fadeOut('fast')
}

function openNav(){ 
  $('.mobileNav').fadeIn('fast')
}


  async function submitCredentials1 (event) { 

        setAddModalShowLoading(true)
        window.responsiveVoice.speak(`Hold on while I create your demo session.. ${firstname} `) 
            event.preventDefault();
            console.log('alternate login ran')
            const requestedResponse = await fetch('https://atcbrain-7de683f08325.herokuapp.com/demo-engine/resource-api/v1/auth',{ 
            method:"POST",
            body:JSON.stringify({ 
               firstname: firstname,
               email: email
            }),
            headers:{
              "content-type": "application/json"
            }, 
            mode:"cors",
            credentials:"include"
            })
            .then(response => response.json())
            .then(response => {
                console.log(response)
             if(!response){
                setAddModalShowLoading(false)
                setAddModalShow(true)
              
       
                console.log('wrong password')
               let  modalContent = document.getElementById('wrongpassword')
               modalContent.innerHTML = "Sorry, Password incorrect! "
               window.responsiveVoice.speak("You need to check that Password and try again!") 
  
               }

               if(response.err){
                setAddModalShowLoading(false)
                setAddModalShow(true)
              
       
                console.log('wrong password')
               let  modalContent = document.getElementById('wrongpassword')
               modalContent.innerHTML = "Sorry, Username not correct. Remember, Usernames are case sensitive!"
             return  window.responsiveVoice.speak("Your username is case sensitive, please try again") 
               }
      
      
                  if(response){
                    setAddModalShowLoading(false)
            
               window.open("https://demo.code14-Labs.tech/dashboard/home","_blank");
               //  document.location.replace('/dashboard/home')
           
               }
             
        
            })
            .catch(error => console.error('Error', error));
      
      }



      window.onscroll = () => { 


        let items = document.getElementsByClassName('atc-nav-item')

        let speech = document.getElementById('speech')
        let text = document.getElementById('textMessage')
        let voice = document.getElementById('voiceMessage1')


        if(window.scrollY > 2){ 

          $(items).fadeOut('slow')

        }else if(window.scrollY < 1){
         
          $(items).fadeIn('slow')

        }


        if(window.scrollY > 700 && window.scrollY < 1000){

          $(speech).fadeIn('slow')
          setSpeechBox(blueBox)

          text.innerHTML = " Listen to a summary on our impact in schools!"
          setVoiceMessage(" As at today we supply books to over 20 school campuses across 4 states in Nigeria... This supports teaching in learning across both primary and secodary education! ")

        }

        if(window.scrollY > 1000 && window.scrollY < 2900){

             text.innerHTML = "Learn about the expected learning outcome!"
             setVoiceMessage("We have the most technical curriculum for pupils which progresses from computational thinking to text-based computer languages... Aiding in the wholistic development of your learners.")
             setSpeechBox(amberBox)
        }

        if(window.scrollY > 2800 && window.scrollY < 4200){

          text.innerHTML = "Learn about our partnership with teachers!"
          setVoiceMessage("All i can say is...Watch the video!!!")
          setSpeechBox(redBox)
     }

     if(window.scrollY > 3500 && window.scrollY < 4300){

      text.innerHTML = "Understand how we decide on tools to build"
      setVoiceMessage("Our priority is the teachers and how to make thier lives better, We build features which support collaboration and learning science.")
      setSpeechBox(greenBox)
 }

     if(window.scrollY > 4300 && window.scrollY < 4800){

      text.innerHTML = "Get deatils on teacher trainings & development!"
      setVoiceMessage("We have a network of over 130 Computer teachers who are running our free certification programme. Use the button to learn more")
      setSpeechBox(amberBox)
 }

 if(window.scrollY > 4800 && window.scrollY < 5500){

  text.innerHTML = "We price books based on tools provided within"
  setVoiceMessage("We price books based on tools provided within")
  setSpeechBox(purpleBox)
}

 if(window.scrollY > 5500 && window.scrollY < 6500){

  text.innerHTML = "Payments for simple, I can guide you if needed!"
  setVoiceMessage("Payements for simple, If you require device and internet support please indicate when filling the form.")
  setSpeechBox(greenBox)

}
        
        if(window.scrollY < 700){
          $(speech).fadeOut('slow')
        }
      }


      function playVoiceMessage(){ 
  
        return  window.responsiveVoice.speak(`${voiceMessage}`) 
      }
   

    return (
     <div className="container-fluid paddingOff">

<nav className="navbar navbar-expand-lg px-5 d-none d-sm-flex fixed-top atc-nav">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="Logo" style={{ width: '50px', borderRadius: '50%', border: '3px solid #FFD700' }} />
                     </a>
                     <div id="speech"  style={{ background:`url(${speechBox})`}}> 
                        <div id="speechDesign">
                            <p className="marginOff bodyFont boldFont" id="textMessage"> </p>
                             <button class=" btn btn-primary ply-btn-speech" onClick={playVoiceMessage} >
                             <img class="pe-1" id="voiceMessage1" src={Play}  />
                                 Play
                             </button>
                        </div>
                     </div>  
                 
              
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link atc-nav-item" href="#schools">Schools</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link atc-nav-item" href="#titles">Our Titles</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link atc-nav-item" href="#features">Key Features</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link atc-nav-item" href="#price">Pricing</a>
                        </li>
                        <li className="nav-item d-lg-none">
                            <a className="btn btn-primary atc-btn bodyFont input-position" 
                             href="#payment" >Make Payment </a>
                        </li>
                    </ul>
                </div>

     <a href="#payments" className="link"> 
                     <button className="btn btn-primary d-none d-lg-block bodyFont" style={{
                    backgroundColor: 'white',
                    color: '#000000',
                    border: '2px solid #007bff',
                    borderRadius: '25px',
                    padding: '0 20px',
                    height: '40px',
                }}> Make Payments </button>
                </a>
            </nav>

       {/* nobile nav start */}     
      <nav className="navbar navbar-expand-lg px-2 fixed-top d-block d-sm-none atc-nav">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="Logo" style={{ width: '50px', borderRadius: '50%', border: '3px solid #FFD700' }} />
                     </a>

                     <div id="speech"  style={{ background:`url(${speechBox})`}}> 
                        <div id="speechDesign">
                            <p className="marginOff bodyFont boldFont" id="textMessage"> </p>
                             <button class=" btn btn-primary ply-btn-speech" onClick={playVoiceMessage} >
                             <img class="pe-1" id="voiceMessage1" src={Play}  />
                                 Play
                             </button>
                        </div>
                     </div>     

                <img className="d-block d-sm-none lines" onClick={openNav} src={lines} width="8%" />
                     
            </nav>
 
         <div className="mobileNav"> 
         <div className="topBar text-end"> 
         <img src={close} onClick={closeNav} className="close pt-4 me-4" width="7%" />
         </div>

         <div className="menuItems text-center"> 

         <ul className='paddingOff mt-5'> 
          <li className="bodyFont pt-4 pb-4 menuItemSize" onClick={closeNav}> Schools </li>
          <li className="bodyFont pt-4 pb-4 menuItemSize" onClick={closeNav}> Titles </li>
          <li className="bodyFont pt-4 pb-4 menuItemSize" onClick={closeNav}> Key Features </li> 
          <li className="bodyFont pt-4 pb-4 menuItemSize" onClick={closeNav}> Pricing </li> 
          <li> <a href="#payments" className="link"> 
                     <button className="btn btn-primary bodyFont" style={{
                    backgroundColor: 'white',
                    color: '#000000',
                    border: '2px solid #007bff',
                    borderRadius: '25px',
                    padding: '0 20px',
                    height: '40px',
                }}> Make Payments </button>
                </a>
          </li>
         </ul>
         
         </div>

         <img src={andrew} className="mobileIcon" width="40%" />
                 
         </div>
  
            {/* Banner Section */}
            <div className="banner text-center px-5">
                <h1 className="text-light headingSize marginOff bodyFont d-none d-sm-block"> Hello Coder, Start Learning <br /> Computer Language With Andrew Today! </h1>
                <h4 className="text-light marginOff bodyFont headingSizeMob  d-block d-sm-none"> Hello Coder,<br /> Start Learning <br /> Computer Language <br /> With Andrew Today! </h4>
                {/* Add the icon image below the text */}
                <img src={union} alt="Underlining Icon" className="underline d-none d-sm-block"  />
                <p className="lead bodyFont"> Begin a 1-min coding class! </p>

                <div className="row justify-content-center input-position">
                    <div className="col-md-3">
                        <input
                            type="text"
                            className="form-control mb-3 input-custom formfield"
                            placeholder="Firstname"
                            onChange={updateFirstnameField} 
        
                        />
                    </div>
                    <div className="col-md-3">
                        <input
                            type="email"
                            className="form-control mb-3 input-custom formfield"
                            placeholder="email"
                            onChange={updateEmailField} 
                        
                        />
                    </div>
                </div>
                <button className="btn btn-primary atc-btn bodyFont input-position" onClick={submitCredentials1}>Try it Now</button>


                {/* Divider with Andrew image */}
                <div className="divider mt-5 marginOffMob andrewHolder col-12">
                    <img src={andrew} alt="Andrew" className='andyWidth' /> 
                </div>

                {/* Transparent banner image */}
                <div className="transparent-image d-none d-sm-block" style={{ position: 'absolute', bottom: '30px', width: '100%', zIndex: '1', padding: '0 60px' }}>
                    <img src={banner} alt="Banner" className="d-none d-sm-block" style={{ width: '100%', objectFit: 'contain', padding: '56px 0' }} />
                </div>
            </div>

            <Modal show={addModalShowLoading} animation={false} >
  
  <Modal.Body id="" className="black-background-trans white-text padding-off"> 

<div className="row"  id=""> 

  <div className="col-md-12" > 

    <figure className="text-center standard-padding-tb nav-clear-space-screen"> 
       
        <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
        <figcaption className="standard-margin-top-quiz">
            <p className='bodyFont blackText'> Building up your demo session!  </p>
        </figcaption> 
    </figure>


  </div>

  </div>

 

   </Modal.Body>

</Modal>       




<Modal show={addModalShow} animation={false} >
  <Modal.Body className="black-background-trans text-center"> 
  
  <div className='text-center'>  
   <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
   </div>
   <div id="wrongpassword" className='text-center'>  
   <img className="standard-margin-top-quiz" src={atcLogo} width='100' />
   </div>
   
  <button id="" onClick={()=> setAddModalShow(false)} className="btn atc-btn bodyFont mt-3"> Try again </button>
 

   </Modal.Body>

</Modal> 

            </div>

            

    );
};

export default Home;