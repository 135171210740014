import React from "react";
import "./Key-features.css";
import Speechimg from "../assets/Speech Box.png";
import Keyfeatures from "../assets/Key Features.png";
import OwlCarousel from "react-owl-carousel";
import Pc from "../assets/laptop.png";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
function KeyFeatures() {
  const options = {
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: true,
    dots: true,
  };
  return (
    <div class="parent pt-5" id="features">
      <div class="row rw">
        <div class="col-12 col-md-5 pb-3 mx-auto text-center">
          <img className="" src={Keyfeatures} />
          <p>
            Teachers lead the teaching and learning process while using Andrew
            Teaches Coding supported by some awesome features to support
            pedagogical practices in your classrooms!
          </p>
        </div>
      </div>

      <OwlCarousel
        className="carousel features"
        loop
        margin={40}
        items={3}
        nav
        autoplay
        {...options}
      >
        <div id="item-1" class="item">
       
        <video class="vid" controls>
                  <source src="https://res.cloudinary.com/code4teen-tech/video/upload/v1730159297/motion/VIDEO-2024-10-27-07-02-49.mp4" />
                </video>
          <h1>Syntax-Based</h1>
         
        </div>
        <div id="item-2" class="item">
        <img class="pc-img" src={Pc} />
          <h1>Guided Professional Projects</h1>
         
        </div>
        <div id="item-3" class="item ">
        <img class="pc-img" src={Pc} />
          <h1>Automatic code check and hints</h1>
         
        </div>
   
        <div id="item-5" class="item ">
          <img class="pc-img" src={Pc} />
          <h1>Progressive</h1>
         
        </div>
      </OwlCarousel>
    </div>
  );
}
export default KeyFeatures;
