import React from "react";
import "./Schools.css";
import OwlCarousel from "react-owl-carousel";
import OwlCarousel2 from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import NotHovered1 from "../assets/Frame 58.jpg";
import NotHovered2 from "../assets/Frame 59.jpg";
import NotHovered3 from "../assets/Frame 60.jpg";
import NotHovered4 from "../assets/Frame 61.jpg";
import NotHovered5 from "../assets/Frame 62.jpg";
import NotHovered6 from "../assets/Frame 63.jpg";
import NotHovered7 from "../assets/Frame 65.jpg";
function Schools() {
  const options = {
    responsive: {
      0: {
        items: 3,
      },
      400: {
        items: 3,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      800: {
        items: 3,
      },
      1000: {
        items: 7,
      },
    },
    nav: false,
    dots: false,
  };
  return (
    <div class="bodyFont">
      <section class="col-md-6 mx-auto pb-3 " id="schools">
        <h2 class="text-center mb-4 fw-semibold inter">
          Interactive Text/Workbooks for Mastery of Computer Languages in your
          Classroom
        </h2>
        <h6 class="text-center fw-normal">
          Our Textbooks trusted by several Elementary Schools
        </h6>
      </section>
      <section class="col-12 col-md-10 pb-5 mx-auto">
        <OwlCarousel
          className="owl-theme"
          mouseDrag={false}
          touchDrag={false}
          pullDrag={false}
          loop={true}
          autoplay={true}
          items={7}
          margin={5}
          {...options}
        >
          <div class="item text-center">
            <img src={NotHovered1} class="ig" />
          </div>
          <div class="item text-center">
            <img src={NotHovered2} class="ig" />
          </div>
          <div class="item text-center">
            <img src={NotHovered3} class="ig" />
          </div>
          <div class="item text-center">
            <img src={NotHovered4} class="ig" />
          </div>
          <div clas="item text-center">
            <img src={NotHovered5} class="ig" />
          </div>
          <div class="item text-center">
            <img src={NotHovered6} class="ig" />
          </div>
          <div class="item text-center">
            <img src={NotHovered7} class="ig" />
          </div>
        </OwlCarousel>
      </section>
    </div>
  );
}
export default Schools;
