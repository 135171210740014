import React from "react";
import "./Textbook.css";
import Pencil from "../assets/Pencil.png";
import Titles from "../assets/Titles.png";
import Thinking from "../assets/thinking.png";
import Desktop from "../assets/html.png";
import Play from "../assets/play.png";
import Desktop2 from "../assets/css.png";
import Desktop3 from "../assets/pro.png";
import Nigerianboy from "../assets/Nigerain Boy.png";
import Nigeriangirl from "../assets/Nigerain Girl.png";
import Whiteboy from "../assets/Foreign Boy.png";
import Whitegirl from "../assets/Foreign Gurl.png";
import kamsi from "../assets/kamsi.mp4";
import $ from "jquery";
import { IoIosArrowDown } from "react-icons/io";
import { IoChevronDownSharp } from "react-icons/io5";
function Textbook() {
  function Playvideo(e) {
    e.preventDefault();

    $("html,body").animate(
      {
        scrollTop: $("#dscrption").offset().top,
      },
      "fast"
    );
  }

  function Playvideo1(e) {
    e.preventDefault();

    $("html,body").animate(
      {
        scrollTop: $("#dscrption1").offset().top,
      },
      "fast"
    );
  }
  function Playvideo2(e) {
    e.preventDefault();

    $("html,body").animate(
      {
        scrollTop: $("#dscrption2").offset().top,
      },
      "fast"
    );
  }
  function Playvideo3(e) {
    e.preventDefault();

    $("html,body").animate(
      {
        scrollTop: $("#dscrption3").offset().top,
      },
      "fast"
    );
  }

  function allocateActiveState(e){ 

    let id = "#" + e.target.value
    
    alert(id)
    $('#pills-home1, #pills-home, #pills-profile').removeClass('active show')
    $(id).addClass('active show')
  }

  return (
    <div class="bodyFont pb-5">
      <div class="hd col-12 mx-auto text-center">
       
        <img class=" title" src={Titles} />
        <p class=" p-text col-12 col-md-5 mx-auto pt-3 pb-3">
          With 3000+ textbooks sold and numerous school supply contracts, Andrew
          Teaches Coding have advanced literacy in computer languages.
        </p>
      </div>
      <div class="cnt pt-4">
        <ul class="tbs nv-tbs nav nav-pills " id="pills-tab" role="tablist">
          <li class="col-3 nav-item" role="presentation">
            <button
              class="full--width nv-btn nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home1"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Engineering Thinking
            </button>
          </li>
          <li class="col-3 nav-item" role="presentation">
            <button
              class="full--width nv-btn nav-link "
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
            >
              Html & The Web
            </button>
          </li>
          <li class=" col-3 nav-item" role="presentation">
            <button
              class=" full--width nv-btn nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
            >
              Interface Design 1
            </button>
          </li>
          <li class=" col-3 nav-item" role="presentation">
            <button
              class="full--width nv-btn nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
            >
              Interface Design 2
            </button>
          </li>
        </ul>

        <div >
          <select class="select-container" onChange={allocateActiveState} >
          <option value="pills-home1"> Computational Thinking </option>
            <option value="pills-home">Web Design Beginner </option>
            <option value="pills-profile">Web Design Intermediate</option>
            <option value="pills-contact">We Design Pro</option>
          </select>
        </div>

        <div class="tab-content container--fluid" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home1"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabIndex="0"
          >
            <div class="div--mb div--lg d-flex  pt-5">
              <div class="div-1 text-center">
                <img class="desktop" src={Thinking} width="50%" />
              </div>
              <div class=" div-2 ps-5 p-change">
                <h3>Engineering Thinking</h3>
                <p>
                  Students gain a practical understanding of the basics of web
                  publishing and are able to transcribe any body of text
                  creatively into web formats using the HTML language.
                </p>
                <button onClick={Playvideo3} class=" btn btn-primary ply-btn ">
                  <img class="pe-3" src={Play} />
                  Play video
                </button>
               
                  
              

                <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="true" aria-controls="collapseOne">
        Learning Outcome
      </button>
    </h2>
    <div id="collapseNine" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
       <h5> The key goal is to shape the child’s perspective and consciousness around how tasks are carried out, aiding them to see all the moving parts and components required to complete a task as both independent and consequential to the final expected result. </h5> </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTwo">
       Cross Curriculum Overlap
      </button>
    </h2>
    <div id="collapseTen" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p> Thinking Skills </p>
        <p> Problem solving </p>
        </div>
    </div>
  </div>

</div>
              </div>
              
            </div>
            <div class="dscrption" id="dscrption3">
              <div class="col-12 col-md-7 mx-auto text-center dscr" id="dscrp">
                <video class="vid" controls>
                  <source src={kamsi} />
                </video>
              </div>

              <img className="nigerianboy" src={Nigerianboy} />
              <img className="nigeriangirl" src={Nigeriangirl} />
              <img className="whiteboy" src={Whiteboy} />
              <img className="whitegurl" src={Whitegirl} />
            </div>
          </div>
          <div
            class="tab-pane fade "
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabIndex="0"
          >
            <div class="div--mb div--lg d-flex  pt-5 pb-5 ">
              <div class="div-1">
                <img class="desktop" src={Desktop} />
              </div>
              <div class=" div-2 ps-5 p-change">
                <h3>HTML and the web</h3>
                <p>
                  Students gain a practical understanding of the basics of web
                  publishing and are able to transcribe any body of text
                  creatively into web formats using the HTML language.
                </p>
                <button onClick={Playvideo} class=" btn btn-primary ply-btn ">
                  <img class="pe-3" src={Play} />
                  Play video
                </button>
               
 <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseOne">
        Learning Outcome
      </button>
    </h2>
    <div id="collapseFive" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
     <h5> Students are expected to take thier understand of web publishing to transform their expression of creative writing. Students gain the ability to explore use of media formats outside text and digital medium outside of the traditional word editor to express the storylines, while reinforcing their understanding of the key learning concepts </h5>  </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseTwo">
       Cross Curriculum Overlap
      </button>
    </h2>
    <div id="collapseSix" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p> Creative Writing </p>
        </div>
    </div>
  </div>

</div>
             
         

              </div>
            </div>
            <div class="dscrption" id="dscrption">
              <div class="col-12 col-md-8 mx-auto text-center dscr pt-5 ">
                <video class="vid" controls>
                  <source src={kamsi} />
                </video>
              </div>

              <img className="nigerianboy" src={Nigerianboy} />
              <img className="nigeriangirl" src={Nigeriangirl} />
              <img className="whiteboy" src={Whiteboy} />
              <img className="whitegurl" src={Whitegirl} />
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
            tabIndex="0"
          >
            <div class=" div--mb d-flex pt-5 p-change">
              <div class="div-1">
                <img class="desktop--2" src={Desktop2} />
              </div>
              <div class="div-2 ps-5">
                <h3>Interface Design & Analysis 1</h3>
                <p>
                  Students progress towards implementation of styles to give
                  their interfaces a more professional outlook is gained. They
                  learn grammatical rules associated with implementing CSS
                  language in combination with HTML to express and communicate
                  ideas fluently in web format. All activities are made possible
                  by combining HTML language from with selective portions of
                  CSS.
                </p>
                <button onClick={Playvideo1} class=" btn btn-primary ply-btn ">
                  <img class="pe-3" src={Play} />
                  Play video
                </button>
              
 <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        Learning Outcome
      </button>
    </h2>
    <div id="collapseOne" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <h5>Core Concepts of Desktop publishing, under the new ICT structure is reinforced as students come incontact with text styling charactertisitcs such as Font types,Styles, alignmnent and color. These concepts are then implemented in an alternative manner for web publishing under the Andrew teaches coding learning scheme.</h5> </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
       Cross Curriculum Overlap
      </button>
    </h2>
    <div id="collapseTwo" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p> Creative Design </p> 
      <p> Analysis </p>  
       <p> Creative Writing </p>  
      
       
       </div>
    </div>
  </div>

</div>
              </div>
            </div>
            <div class="dscrption" id="dscrption1">
              <div class="col-12 col-md-8 mx-auto text-center dscr ">
                <video class="vid" controls>
                  <source src={kamsi} />
                </video>
              </div>

              <img className="nigerianboy" src={Nigerianboy} />
              <img className="nigeriangirl" src={Nigeriangirl} />
              <img className="whiteboy" src={Whiteboy} />
              <img className="whitegurl" src={Whitegirl} />
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
            tabIndex="0"
          >
            <div class="div--mb d-flex pt-5">
              <div class="div-1">
                <img class=" desktop--3 ps-5 " src={Desktop3} />
              </div>
              <div class="div-2  p-change">
                <h3>Interface Design & Analysis 2</h3>
                <p>
                  Students progress to mastering abstract analysis of web
                  interfaces. Learners are able to breakdown the grid
                  requirements of web interfaces and define the structural
                  solutions to implementing interfaces. All activities are made
                  possible by combining HTML language from with selective
                  portions of CSS.
                </p>
                <button onClick={Playvideo2} class=" btn btn-primary ply-btn ">
                  <img class="pe-3" src={Play} />
                  Play video
                </button>

               
                  
                <div class="accordion" id="accordionExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingOne">
      <button class="accordion-button drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseOne">
        Learning Outcome
      </button>
    </h2>
    <div id="collapseThree" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <h5>Students understanding of word problem analysis and used of divisions, percentages are reinforced by the term B curriculum. Students will be required to use this knowledge as the foundation for learning key concepts around recreating various web formats in a professional manner.</h5>
        </div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="headingTwo">
      <button class="accordion-button collapsed drp-btn" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseTwo">
       Cross Curriculum Overlap
      </button>
    </h2>
    <div id="collapseFour" class="accordion-collapse collapse drp-dwn" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div class="accordion-body">
      <p> Mathematics </p>  
       <p> Analysis </p>  
       <p> Creative Design Writing </p>  
      
       
        </div>
    </div>
  </div>

</div>
              </div>
            </div>

            <div class="dscrption" id="dscrption2">
              <div class="col-12 col-md-8 mx-auto text-center dscr  ">
                <video class="vid" controls>
                  <source src={kamsi} />
                </video>
              </div>

              <img className="nigerianboy" src={Nigerianboy} />
              <img className="nigeriangirl" src={Nigeriangirl} />
              <img className="whiteboy" src={Whiteboy} />
              <img className="whitegurl" src={Whitegirl} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Textbook;
