import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Eclipse1 from "../assets/Eclipse1.png";
import Eclipse2 from "../assets/Eclipse2.png";
import Teacher from "../assets/Teacher.png";
import Subtract from "../assets/Subtract.png";
import ShortTeach from "../assets/short-teacher.png";
import Round from "../assets/round-mb.png";
import "./Teacher-training.css";
function TeacherTraining() {
  return (
    <>
      <div class="bodyFont cntner-fluid">
        <div class="row  r-w">
          <div class="crd crd--mb card mb-3">
            <img class="bckg-img-1" src={Eclipse1} />
            <img class="bckg-img-2" src={Eclipse2} />
            <div class="row g-0">
              <div class=" col-lg-4 d-none d-lg-block">
                <img class="bckg-img" src={Subtract} />
                <img
                  src={Teacher}
                  class="skillup--img img-fluid rounded-start"
                  alt="Teacher image"
                />
              </div>
              <div class="col-12 col-lg-8">
                <div class="card-body crd-bdy">
                  <h5 class="crd--titl card-title">
                    Skill up your Teachers with our free Continuous Professional
                    Development Certification in Computer Science
                  </h5>
                  <p class=" crd--p card-text">
                    Teacher ownership starts with teacher upskilling. Sign up your teacher for free for our 
                    professional development plan in HTML, CSS and Javascript. 
                  </p>
                 
                    <a href="https://code14-labs.tech/teacher-training" target='_blank'>
                    <button class=" enroll--btn bg-light">Enroll Now</button>
                    </a>
                   
       
                </div>
              </div>

              <div class="col-12  col-lg-4 d-block d-lg-none">
                <img
                  src={ShortTeach}
                  class="skillup--img img-fluid rounded-start"
                  alt="Teacher image"
                />
                <img class="bck-mb" src={Round} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TeacherTraining;
