import React from "react";
import "./Footer.css";
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import andrew from "../assets/Andrew.png";
import bcouncil from "../assets/bc.png";
function Footer() {
  return (
    <div class="bodyFont">
      <div id="container">
        <div className="row" id="content">
          <div className="d-flex col-12 col-md-6" id="description">
            <img src={andrew} alt="Andrew Teaches Coding" id="img" />
            <div>
              <h6 id="heading">Andrew Teaches Coding</h6>
              <p>
                Andrew Teaches Coding is a complete text/workbooks series for
                teaching computer science from k - 9. Our resources cover HTML,
                CSS, Javascript and core computer science concepts offering both
                digital and print-based formats.
              </p>
            </div>
          </div>
          {/* <div className='container-lg d-flex' id='contact'>
            <div className='row'> */}
          <div className="col-12 offset-md-2 col-md-3" id="contactus">
            <h6 id="heading">Contact</h6>
            <ul id="email" className="d-flex">
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="text-primary" />
              </li>
              <li>support@andrewteachescoding.org</li>
            </ul>

            <div className="d-flex justify-content-between" id="socials">
              <a
                href="https://www.instagram.com/andrewteachescoding/"
                className="btn btn-outline-white text-primary "
                id="social-app"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61559732590404"
                className="btn btn-outline-white text-primary"
                id="social-app"
              >
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a
                href="https://www.youtube.com/@atc-andrewteachescoding8715"
                className="btn btn-outline-white text-primary"
                id="social-app"
              >
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-1" id="service">
            <h6 id="heading">Service</h6>
            <ul className="navbar-nav" id="our-services">
              <li className="nav-item">
                <a className="link" href="#titles">
                  {" "}
                  Our Titles{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="link" href="#schools">
                  {" "}
                  Schools{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="link" href="#features">
                  {" "}
                  Features{" "}
                </a>
              </li>
              <li className="nav-item">
                <a className="link" href="#price">
                  {" "}
                  Pricing{" "}
                </a>
              </li>
            </ul>
          </div>
          {/* </div> 
        </div> */}

          <div className="row mt-5">
            <div className="col-12 text-center">
              <h6 className="pt-2 pb-2"> Supported by the </h6>
              <img src={bcouncil} width="10%" />
              <h5 className="pt-2"> Under International Literacy Day </h5>
            </div>
          </div>
        </div>
        <hr id="hr" />
        <footer className="text-center">
          2024. All Rights Reserved. Andrew Teaches Coding.
        </footer>
      </div>
      <div id="blue-line"></div>
    </div>
  );
}
export default Footer;
